
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onSearch() {
      this.$emit("search", this.search);
    },
  },
};
