
export default {
  props: ["links"],
  data: () => ({
    isShowAll: false,
  }),
  mounted() {
    // console.info(this.links);
  },
};
