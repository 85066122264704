
export default {
  props: {
    withDropdown: { type: Boolean, default: true },
    id: {
      default: null,
    },
    listingData: {
      default: {
        created_at: "",
        description: "",
        total_comment: 0,
        community_content_media: [],
        total_like: 0,
        id_community: null,
        total_views: 0,
        id: null,
        slug: "",
        user: {
          avatar: "",
          username: "",
          level: "",
        },
      },
    },
    marketplaceListingdata: {
      default: {
        id: null,
        id_user: null,
        title: "",
        slug: "",
        description: "",
        id_brand: null,
        price: 0,
        id_city: null,
      },
    },
  },
  data: () => ({
    listingurl: "",
  }),
  created() {
    this.listingurl = process.env.BASE_LISTING;
    const idComm = this.$route.params.id;

    this.link = process.env.BASE_LISTING + "detail/" + idComm + "/" + this.id;
  },
};
